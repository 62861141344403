.player {
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.time-control {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  input {
    width: 100%;
    padding: 1rem 0rem;
    cursor: pointer;
    -webkit-appearance: none;
    background: transparent;
  }
  p {
    padding: 1rem;
  }
}

.play-control {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 1rem;
  width: 50%;
  margin-left: 10%;
  svg {
    cursor: pointer;
  }
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
}

input[type="range"]::-moz-range-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
}

.track, .volume-track {
  background: lightblue;
  width: 100%;
  height: 1rem;
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
}

.volume-control {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
  input {
    width: 100%;
    margin: auto;
    padding: 1rem 0rem;
    cursor: pointer;
    -webkit-appearance: none;
    background: transparent;
  }
}

.volume-handler {
  min-width: 36px;
}

.volume-track {
  position: absolute;
  left: 120%;
  width: 0%;
}

.animate-track, .animate-volume-track {
  background: rgb(204, 204, 204);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(0%);
  pointer-events: none;
}

@media screen and (max-width: 768px) {
  .time-control {
    width: 90%;
  }
  .play-control {
    width: 80%;
    margin-left: 0;
  }
  .volume-control {
    display: none;
  }
}